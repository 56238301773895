@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Lobster", cursive;
    src: url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
  }
}

@layer base {
  @font-face {
    font-family: "RocknRoll One", sans-serif;
    src: url("https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap");
  }
}

body {
  font-family: "Lobster";
}

.logo {
  font-family: "RocknRoll One";
}

.toggle__dot {
  top: -0.25rem;
  left: -0.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #f1fec6;
}

html,
body {
  width: 100%;
  height: 100%;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
